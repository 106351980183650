.cover {
    position: relative;
    background-color: $color-light;
    padding: 30px 0 40px;

    &__container {
        display: flex;
        column-gap: 30px;
    }

    &__image-wrapper {
        width: 570px;
        height: 350px;
        background-color: $color-bg--image;
        flex: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 15px;
    }

    &__title {
        color: $color-dark;
        margin: 0;
    }

    &__intro {
        color: $color-text;
        font-size: toRem(18);
        line-height: toRem(24);
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
// 1200
@media screen and (max-width: $large) {

    .cover {
        &__container {
            column-gap: 20px;
        }

        &__image-wrapper {
            height: 290px;
            max-width: 470px;
            width: 100%;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__container {
            flex-direction: column;
            row-gap: 20px;
        }
    }

}