.home {
    h2 {
        margin: 0;
    }

    &__title {
        font-size: toRem(60);
        color: $color-dark;
        font-weight: $font-weight-medium;
    }

    .button {
        column-gap: 5px;
    }

    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .alu {
        position: relative;

        &:before {
            content: '';
            @include size(100%, 215px);
            @include absolute($top:0);
            z-index: 3;
            background: linear-gradient(180deg, #092D46 0%, rgba(9, 45, 70, 0.766423) 55.89%, rgba(9, 45, 70, 0) 99.44%);
        }

        &:after {
            content: '';
            @include size(100%);
            @include absolute($top:0);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.14) 43.41%, rgba(0, 0, 0, 0.7) 94.5%);
            z-index: 2;
        }

        > .container {
            position: relative;
        }

        .container {
            &--content {
                position: relative;
                height: 100%;
            }

            &--navigation{
                @include absolute(0, 0, 0, 0);
                height: 100%;
                pointer-events: none
            }
        }

        .slideshow {
            @include size(100%, 680px);
            z-index: initial;

            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                @include size(100%);
            }

            &__container {
                display: inherit;
                position: relative;
                @include size(100%);
            }

            &--video {
                img {
                    display: none;
                }
            }

            // Navigation (arrow)
            &__navigation {
                @include absolute($top: 0, $right: 0, $left: 0);
                height: 100%;
                pointer-events: none;
        
                &__btn {
                    pointer-events: auto;
                    @include size(40px);
                    margin: 0;
                    padding: 0;
                    background-color: $color-bg--transparent;
                    border: 2px solid $color-white;
                    border-radius: 50px;
                    top: 310px;
                    box-shadow: 5px 5px 30px 0px #132A3B66, 0px 0px 1px 0px #132A3B;
                    z-index: 3;

                    &::before, &::after {
                        display: none
                    }
        
                    svg {
                        @include size(26px);
                        fill: $color-white;
                    }
        
                    &:hover, &:focus {
                        background-color: $color-white;
        
                        svg {
                            fill: $color-dark;
                        }
                    }

                    &--prev {
                        left: 15px;
                    }

                    &--next {
                        right: 15px;
                    }
                }
            }
        
        }
    }

    //======================================================================================================
    // Accès rapides
    //======================================================================================================
    .access {
        position: relative;
        z-index: 3;
        margin-top: -200px;

        &__container {
            @include flex($justifyContent: flex-end, $alignItems:flex-end);
            height: 580px;
        }

        &__list {
            @include flex($wrap: wrap, $justifyContent: center);
            background-color: $color-dark;
            width: 400px;
        }

        &__box {
            @include flex($alignItems: center, $justifyContent: center);
            flex: 1 1 50%;
            height: 145px;
            border: 1px solid $color-white--rgba;
        }
        
        &__item {
            position: relative;
            @include flex($alignItems: center, $justifyContent: center, $direction: column);
            @include size(100%);
            overflow: hidden;

            &:hover, &:focus-within {
                .access {
                    &__title {
                        &:after {
                            width: calc(100% + 15px);
                            height: calc(100% + 8px);
                            max-width: calc(100% + 5px);
                        }
                    }
                }
            }

            &--external {
                &:before,
                &:after {
                    content: '';
                }

                &:before {
                    @include absolute($top: -30px, $right: -30px);
                    @include size(60px);
                    background: $color-white--rgba;
                    border-radius: $border-radius--round;
                }

                &:after {
                    background: url(/wp-content/themes/plumelin/assets/src/images/sprite-images/pictos/ic_exit.svg) no-repeat;
                    @include size(20px);
                    @include absolute($top: 2px, $right:2px);
                }
            }
        }

        &__image {
            @include size(100px,75px);

            img {
                object-fit: cover;
                @include size(100%);
            }
        }

        &__title {
            @include flex($direction: column, $alignItems: center);
            position: relative;
            font-family: $font-family--heading;
            color: $color-white;
            text-transform: uppercase;
            font-size: toRem(15);
            font-weight: $font-weight-semibold;
            z-index: 1;
            text-align: center;

            &:after {
                content: '';
                @include absolute($bottom:-5px);
                @include size(104px,15px);
                max-width: 100%;
                background-color: $color-main;
                border-radius: 50px;
                z-index: -1;
                transition: $duration ease-in-out;
            }
        }
    }

    //======================================================================================================
    // Zone mixte posts / events - A la une
    //======================================================================================================
    .mixte {
        position: relative;
        padding-bottom: 90px;    
        margin-top: -580px;

        &:after {
            content: '';
            @include size(100%,385px);
            @include absolute($bottom:0);
            background-color: $color-light;
            z-index: -1;
        }

        &__title {
            color: $color-white;
            font-size: toRem(80);
            line-height: toRem(65);
            font-weight: $font-weight-semibold;
            margin: 0 0 20px 15px;
            position: relative;
            z-index: 4;

            &:after {
                display: none;
            }
        }

        &__container {
            @include flex($wrap: wrap);
            row-gap: 50px;
            position: relative;

            &:after {
                content: '';
                background: url(/wp-content/themes/plumelin/assets/src/images/sprite-images/pictos/bg_plumes.svg) no-repeat;
                background-size: contain;
                @include size(954px,292px);
                @include absolute($left: -480px,$bottom: 160px);
                z-index: -1;
            }
        }

        &__small {
            display: flex;
            width: 100%;
            column-gap: 30px;
        }

        &__item {
            position: relative;
            box-shadow: 1px 1px 10px 0px #1B233614, 0px 0px 1px 0px #1B233614;
            background-color: $color-white;
            padding: 15px;
            width: calc((100% / 3) - 20px);
            height: 330px;
            overflow: hidden;

            &:hover, &:focus-within {
                .mixte {
                    &__item {
                        &__taxo {
                            background-color: $color-dark;
                            color: $color-white;
                        }

                        &__more {
                            svg {
                                bottom: 3px;
                                right: 3px;
                            }

                            &:after {
                                bottom: -25px;
                                right: -25px;
                            }
                        }
                    }
                }
            }

            &__image {
                @include size(100%,180px);
                background: url($urlShort + '/posts-icons/post.svg') no-repeat $color-gray center;

                img {
                    @include size(100%);
                    object-fit: cover;
                }
            }

            &__date {
                display: inline-flex;
                align-items: center;
                font-family: $font-family--heading;
                background-color: $color-second;
                font-size: $font-size--4;
                font-weight: $font-weight-bold;
                padding: 0 20px;
                height: 44px;
                border-radius: 0 20px 20px 0;
                @include absolute($left:0, $top:30px);
            }

            &__title {
                font-size: $font-size--4;
                line-height: toRem(24);
                color: $color-text;
                margin: 10px 0 0;
            }

            &__taxo {
                display: inline-flex;
                align-items: center;
                background-color: $color-light;
                transform: translateY(-50%);
                height: 30px;
                padding: 0 10px;
                text-transform: uppercase;
                font-size: toRem(13);
                line-height: toRem(20);
                transition: all $duration;
            }

            &__more {
                @include absolute($bottom: 2px, $right:2px);
                @include size(20px);

                svg {
                    @include size(20px);
                    position: relative;
                    z-index: 1;
                    bottom: 0;
                    right: 0;
                    transition: $duration ease-in-out;
                }

                &:after {
                    content: '';
                    @include absolute($bottom: -32px, $right:-32px);
                    @include size(60px);
                    border-radius: $border-radius--round;
                    transition: $duration ease-in-out;
                }

                &--event {
                    &:after {
                        background-color: $color-second;
                    }

                    svg {
                        fill: $color-dark;
                    }
                }

                &--post {
                    &:after {
                        background-color: $color-main;
                    }

                    svg {
                        fill: $color-white;
                    }
                }
            }

            &--0 {
                @include size(670px,495px);
                display: block;
                z-index: 4;

                .mixte {
                    &__item {
                        &__image {
                            height: 330px;
                        }

                        &__date {
                            top: 35px;
                        }

                        &__title {
                            font-size: $font-size--3;
                            line-height: toRem(32);
                            color: $color-text;
                            margin: 0 0 5px;
                        }

                        &__intro {
                            font-size: toRem(18);
                            line-height: toRem(24);
                        }
                    }
                }
            }
        }

        &__template {
            @include flex($justifyContent: space-between);
            margin-top: 45px;

            &__all {
                display: flex;
                column-gap: 20px;
            }

            &__btn {
                margin: 0;
                padding: 0 30px;

                &--posts {
                    &:hover, &:focus-within {
                        border-color: $color-main; 
                    }
                }
            }
        }
    }

    //======================================================================================================
    // Kiosque
    //======================================================================================================
    .kiosque-images {
        padding: 80px 0;

        &__container {
            @include flex($justifyContent: space-between);
        }
    }

    .kiosque {
        width: 495px;
        flex: none;

        &__item {
            @include flex($alignItems: flex-end);
            column-gap: 25px;
            margin-top: 45px;
        }

        &__image {
            @include size(230px,320px);
            border: 1px solid $color-light;

            img {
                @include size(100%);
                object-fit: cover;
            }
        }

        &__content {
            &__title {
                color: $color-dark;
                margin: 0 0 10px;
            }

            &__extension {
                text-transform: uppercase;
            }
        }

        &__buttons {
            @include flex($direction: column);
            row-gap: 20px;
            margin-top: 20px;
        }

        &__btn {
            @include flex($alignItems: center);
            column-gap: 10px;
            font-family: $font-family--heading;
            text-transform: uppercase;
            font-size: toRem(15);
            line-height: toRem(20px);
            font-weight: $font-weight-semibold;
            text-decoration: underline;
            text-decoration-color: $color-bg--transparent;
            transition: $duration ease-in-out;

            svg {
                background-color: $color-main;
                fill: $color-white;
                @include size(30px);
                border-radius: $border-radius--round;
            }

            &:hover, &:focus-within {
                text-decoration-color: $color-text;
            }
        }

        &__template {
            &__btn {
                margin: 40px 0 0;
                padding: 0;
                width: 270px;
            }
        }
    }

    //======================================================================================================
    // En images - admin area
    //======================================================================================================
    .admin-area {
        width: 600px;
        margin-right: -15px;

        &__title {
            position: relative;
            margin: 10px 0 0 15px;

            span {
                @include absolute($left:85px, $top:55px);
            }
        }

        .bloc {  
            position: relative;
            margin-top: 60px;
            @include size(100%, 370px);
            padding: 15px;

            &:hover, &:focus-within {
                .bloc {
                    &__line-left {
                        &:before {
                            height: 100%;
                        }
                        &:after {
                            width: 100%;
                        }
                    }
                    
                    &__line-right {
                        &:before {
                            width: 100%;
                        }
                        &:after {
                            height: 100%;
                        }
                    }
                }
            }

            &__line-left,
            &__line-right {
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    background-color: $color-main;
                    transition: $duration ease-in-out;
                }
            }

            &__line-left {
                &:before,
                &:after {
                    bottom: 0;
                    left: 0;
                }

                &:before {
                    @include size(4px,270px);
                }

                &:after {
                    @include size(270px,4px);
                }
            }

            &__line-right {   
                &:before,
                &:after {
                    top: 0;
                    right: 0;
                }

                &:before {
                    @include size(100px,4px);
                }

                &:after {
                    @include size(4px,100px);
                }
            }

            &__wrapper {
                position: relative;
                @include flex($alignItems: center, $justifyContent: center);
                @include size(100%);
    
                &--video {
                    cursor: pointer;
    
                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        @include flex($alignItems: center, $justifyContent: center);
                        @include size(50px);
                        border-radius: $border-radius--round;
                        background: url(/wp-content/themes/plumelin/assets/src/images/sprite-images/pictos/ic_play.svg) no-repeat $color-second;
                        box-shadow: $shadow;
                    }
                }
    
            }
    
            &__image {
                @include size(100%);
    
                img {
                    @include size(100%);
                    object-fit: cover;
                    object-position: center;
                }
            }
    
            .popup-area {
                @include flex($alignItems: center, $justifyContent: center);
                @include fixed($top: 0, $left: 0);
                z-index: 20;
                @include size(100%);
                opacity: 0;
                visibility: hidden;
                background-color: $color-black--rgba;
    
                &.show-popup {
                    visibility: visible;
                    opacity: 1;
                }
    
                .accordion {
                    height: 0;
                    color: $color-white;
    
                    svg {
                        fill: $color-white;
                    }
                }
    
                .close-area-popup {
                    @include flex($alignItems: center, $justifyContent: center);
                    cursor: pointer;
                    @include absolute($top: 40px);
                    @extend %SpaceFull-to-Container--right;
                    margin: 0;
                    padding: 0;
                    background-color: $cross-bg;
                    border-radius: $cross-border-radius;
    
                    svg {
                        @include size($cross-size);
                        fill: $cross-color;
                    }
    
                    &:hover, &:focus {
                        background-color: $cross-bg--hover;
    
                        svg {
                            fill: $cross-color--hover;
                        }
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home {
        .alu {
            &:before {
                height: 190px;
            }

            .slideshow {
                height: 550px;
            }
        }

        .access {
            margin-top: -120px;

            &__list {
                width: 320px;
            }
        }

        .mixte {
            padding-bottom: 75px;    
    
            &:after {
                height: 365px;
            }
    
            &__container {    
                &:after {
                    @include size(715px,220px); 
                    left: -345px;
                }
            }
    
            &__small {
                column-gap: 20px;
            }
    
            &__item {
                width: calc((100% / 3) - 10px);
                height: 300px;
    
                &__image {
                    height: 145px;
                }
    
                &__title {
                    font-size: toRem(20);
                    line-height: toRem(24);
                }
    
                &--0 {
                    @include size(555px,495px);
    
                    .mixte {
                        &__item {
                            &__image {
                                height: 270px;
                            }
    
                            &__title {
                                font-size: toRem(28);
                                line-height: toRem(32);
                            }
                        }
                    }
                }
            }
        }

        .kiosque-images {
            padding: 60px 0;
        }
    
        .kiosque {
            width: 450px;
    
            &__item {
                column-gap: 20px;
            }
    
            &__image {
                @include size(200px,280px);
            }
    
            &__content {
                &__title {
                    font-size: toRem(24);
                    line-height: toRem(28);
                }
            }
    
            &__buttons {
                margin-top: 15px;
            }
        }

        .admin-area {
            width: 480px;
            margin-right: -10px;

            &__title {
                margin: 0 0 0 10px;
            }

            .bloc {  
                margin-top: 70px;
                @include size(100%, 295px);
                padding: 10px;
    
                &__line-left {
                    &:before {
                        height: 165px;
                    }
    
                    &:after {
                        width: 165px;
                    }
                }
    
                &__line-right {   
                    &:before {
                        width: 85px;
                    }
    
                    &:after {
                        height: 85px;
                    }
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .home {
        &__title {
            font-size: toRem(52);
            line-height: toRem(55);

            &:after {
                margin-top: 10px;
            }
        }

        .alu {
            &:before {
                height: 145px;
            }

            .slideshow {
                height: 450px;
    
                &--video {
                    video {
                        display: none;
                    }
    
                    img {
                        display: block;
                    }
                }

                &__navigation {
                    &__btn {
                        top: 255px;
                    }
                }
            }
        }

        .access {
            margin-top: -70px;

            &__container {
                height: auto;
            }

            &__list {
                width: 100%;
            }

            &__box {
                flex: 1 1 25%;
            }
        }

        .mixte {
            margin-top: 0;
            padding: 70px 0 60px;
    
            &:after {
                height: 290px;
            }

            &__title {
                color: $color-text;
                font-weight: $font-weight-medium;
                font-size: toRem(52);
                line-height: toRem(55);
                margin: 0 0 30px;

                &:after {
                    content: '';
                    @include size(70px,10px);
                    background: url(/wp-content/themes/plumelin/assets/src/images/sprite-images/shapes/vague_titre.svg) no-repeat;
                    background-size: contain;
                    display: block;
                    margin-top: 10px;
                }
            }
    
            &__container {    
                row-gap: 30px;
                padding-bottom: 75px;

                &:after {
                    left: auto;
                    right: -155px;
                    bottom: -30px;
                }
            }
    
            &__small {
                gap: 20px;
                flex-wrap: wrap;
            }
    
            &__item {
                width: calc((100% / 2) - 10px);
                height: 290px;
    
                &__image {
                    height: 165px;
                }

                &__title {
                    margin: 0;
                }
    
                &--0 {
                    @include size(100%,470px);
    
                    .mixte {
                        &__item {
                            &__image {
                                height: 300px;
                            }
                        }
                    }
                }

                &--3 {
                    @include absolute($bottom: -235px);
                }
            }

            &__template {
                flex-direction: column;
                row-gap: 20px;
                align-items: flex-end;
                max-width: 50%;
                margin-right: 0;
                margin-left: auto;

                &__all {
                    flex-direction: column;
                    row-gap: 20px;
                    max-width: 100%;
                }

                &__btn {
                    width: 285px;
                    max-width: 100%;
                    padding: 0;
                }
            }
        }

        .kiosque-images {
            padding: 60px 0;

            &__container {
                flex-direction: column;
                row-gap: 80px;
            }
        }

        .kiosque {
            width: 100%;

            &__item {
                column-gap: 35px;
                align-items: center;
            }

            &__image {
                @include size(260px,364px);
            }

            &__template {
                &__btn {
                    margin-top: 25px;
                }
            }
        }

        .admin-area {
            width: 100%;
            margin: 0;

            &__title {
                margin: 0;
            }

            .bloc {  
                margin: 70px -10px 0;
                height: fit-content;
                width: calc(100% + 20px);

                &__image {
                    display: flex;
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home {
        .alu {
            .slideshow {
                height: 360px;

                &__navigation {
                    &__btn {
                        top: 200px;
                    }
                }
            }
        }

        .access {
            &__box {
                min-width: 135px;
            }
        }

        .mixte {
            background-color: $color-light;

            &:after {
                top: 0;
                bottom: auto;
                background-color: $color-white;
                max-height: calc(100% - 280px);
                height: 720px;
                z-index: 1;
            }

            &__container {
                padding-bottom: 0;

                &:after {
                    display: none;
                }
            }

            &__item {
                width: 100%;
                height: auto;
                min-height: 260px;
                z-index: 2;

                &__image {
                    height: 145px;
                }

                &--0 {
                    min-height: 370px;
                    height: auto;

                    .mixte {
                        &__item {
                            &__image {
                                height: 160px;
                            }
                        }
                    }
                } 

                &--3 {
                    position: relative;
                    bottom: 0;
                }
            }

            &__template {
                max-width: 100%;
                margin-left: 0;
                margin-right: auto;
                align-items: flex-start;
                position: relative;
                z-index: 2;
            }
        }

        .kiosque {
            &__item {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 25px;
            }
        }

        .admin-area {
            &__title {
                span {
                    left: 0;
                    top: 70px;
                } 
            }

            .bloc {
                &__line-left {
                    &:before {
                        height: 80px;
                    }
                    &:after {
                        width: 80px;
                    }

                }
                &__line-right {
                    &:before {
                        width: 55px;
                    }
                    &:after {
                        height: 55px;
                    }
                }
            }
        }
    }
}
