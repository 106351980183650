.breadcrumb {
    ul {
        display: block;
    }

    li {
        position: relative;
        display: inline;
        color: $color-gray;
        line-height: 1.75;

        a {
            text-decoration: underline;
            text-decoration-color: transparent;

            &:hover, &:focus {
                color: $color-gray;
                text-decoration-color: $color-gray;
            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-gray;
            vertical-align: text-top;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
