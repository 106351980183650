.footer {
    .button {
        column-gap: 5px;
    }

    &__top {
        background-color: $color-light;
        padding: 70px 0 80px;

        &__container {
            display: flex;
        }
    }

    &__bottom {
        &__container {
            height: 105px;
            @include flex($alignItems: center, $justifyContent: space-between);
            column-gap: 25px;
        }
    }

    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--4;
        line-height: toRem(24);
        font-weight: $font-weight-bold;
        margin: 20px 0 15px;
    }

    &__text {
        line-height: toRem(24);
    }

    &__contact {
        padding: 0;
        margin: 25px 0 0;
        width: 230px;

        &:hover {
            border-color: $color-dark;
            color: $color-dark;

            svg {
                fill: $color-dark;
            }
        }
    }

    &__phone {
        @include flex($alignItems: center); 
        color: $color-text;
        margin: 20px 0 0;
        font-weight: $font-weight-bold;
        text-decoration: underline;
        text-decoration-color: $color-bg--transparent;

        svg {
            @include size(26px);
        }
        
        &:hover {
            text-decoration-color: $color-text;
            color: $color-text;
        }
    }

    &__logo {
        @include flex($direction: column);
        margin-right: 55px;

        > svg {
            margin-left: 30px;
        }
    }

    &__address {
        width: 190px;
        margin-right: 85px;
    }

    &__hours {
        width: 330px;
        margin-right: 80px;
    }

    &__rs {
        &__buttons {
            @include flex($alignItems: center); 
            column-gap: 15px
        }
        
        &__btn {
            @include size(40px);
            min-height: 0;
            border-radius: $border-radius--round;
            margin: 0;
            padding: 0;

            svg {
                @include size(26px);
            }

            &:hover {
                border-color: $color-dark;
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &__menu {
        @include flex($alignItems: center, $justifyContent: space-between);
        flex: 1;

        &__link {
            font-size: toRem(12);
            line-height: toRem(20);
            color: $color-text;
            text-transform: uppercase;
            text-decoration: underline;
            text-decoration-color: $color-bg--transparent;

            &:hover {
                text-decoration-color: $color-text;
                color: $color-text;
            }
        }
    }

    &__partners {
        @include flex($alignItems: center);
        column-gap: 15px;

        &__link {
            display: block;
            width: fit-content;
            height: fit-content;
            transition: all $duration ease-in-out;

            &:hover {
                filter: grayscale(1);
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {        
        &__bottom {
            &__container {
                height: 85px;
                column-gap: 20px;
            }
        }
    
        &__contact {
            width: 210px;
        }
    
        &__logo {
            margin-right: 35px;
    
            > svg {
                margin-left: 20px;
            }
        }
    
        &__address {
            margin-right: 65px;
        }
    
        &__hours {
            width: 240px;
            margin-right: 45px;
        }
    
        &__menu {    
            &__link {
                font-size: toRem(10);
            }
        }
    
        &__partners {
            column-gap: 12px;

            &__link {
                &--communaute {
                    img {
                        width: 83px;
                    }
                }
                &--culture {
                    img {
                        width: 73px;
                    }
                }
                &--tourisme {
                    img {
                        width: 54px;
                    }
                }
                &--morbihan {
                    img {
                        width: 44px;
                    }
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .footer {        
        &__top {
            padding: 45px 0 60px;

            &__container {
                align-items: center;
                flex-direction: column;
                row-gap: 15px;
            }
        }

        &__bottom {
            &__container {
                flex-wrap: wrap;
                height: auto;
                min-height: 145px;
                row-gap: 20px;
                padding: 30px 0 15px;
            }
        }
    
        &__contact {
            margin-top: 35px;
        }

        &__phone {
            justify-content: center;
        }
    
        &__logo {
            margin-right: 0;
    
            > svg {
                margin: auto;
            }
        }
    
        &__address, 
        &__hours {
            margin: auto;
            text-align: center;
        }

        &__rs {
            text-align: center;

            &__buttons {
                justify-content: center;
            }
        }
    
        &__menu {    
            width: 100%;
            flex: none;
        }

        &__partners {
            justify-content: center;
            width: 100%;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .footer {
        &__bottom {
            &__container {
                padding: 30px 0 80px;
            }
        }
        
        &__menu {
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 10px;
            justify-content: center;
        }
    }
}
