.search-container {
    display: flex;
    position: relative;
    align-items: center;

    #searchInput {
        height: $search-input-height;
        padding: 20px 70px 20px 24px;
        background-color: $search-input-bg;
        border-color: $search-input-border-color;
        box-sizing: border-box;
        border-radius: 50px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        z-index: 1;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &:focus {
            border-color: $search-input-border-color--active;
        }
    }

    .search-svg {
        @include flex($alignItems: center, $justifyContent: center);
        position: absolute;
        right: 10px;
        @include size($search-input-button-width, $search-input-button-height);
        background-color: $search-input-button-bg;
        border: $search-input-button-border;
        border-radius: $border-radius--round;
        cursor: pointer;
        z-index: 1;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size(15px);
            fill: $search-input-button-color;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $search-input-button-bg--active;
            border-color: $search-input-button-border-color--focus;

            svg {
                fill: $search-input-button-color--active;
            }
        }
    }
}

.searchResult {
    position: relative;
    top: -30px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    background-color: $color-white;

    li {
        position: relative;
        background-color: $color-white;
        border-bottom: 1px solid $color-bg--neutral;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 110px 15px 24px;
            transition: {
                property: color, background-color, padding-left;
                duration: $duration;
                timing-function: $timing;
            }
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: $color-gray;
            transform: translate(0,-50%);
            transition: color $duration $timing;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-gray;
            }
        }

        &:not(.no-results) {
            a:hover, a:focus {
                padding-left: 35px;
                background-color: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }

            &.selected,
            &:hover, &:focus {
                .resultCPT {
                    color: $color-white;
                }
            }
        }

        &:last-child {
            border-bottom: 0;
        }

        &.selected {
            a {
                padding-left: 35px;
                background-color: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 1.125rem/1 dashicons;
                color: $color-text;
                transition: color $duration $timing;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 30px;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $color-bg--neutral;

    .container {
        position: relative;
        height: 100%;

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;   

            &:after {
                content: '';
                background: url(/wp-content/themes/plumelin/assets/src/images/sprite-images/pictos/bg_plumes.svg) no-repeat;
                background-size: contain;
                @include size(954px,292px);
                @include absolute($left: -450px,$top: 110px);
                z-index: -1;
            }
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family--heading;
            font-size: $font-size--1;
            font-weight: $font-weight--heading;
            color: $color-dark;
            text-transform: none;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
        }
    }

    .search-close {
        @include flex($alignItems: center, $justifyContent: center);
        cursor: pointer;
        @include absolute($top: 5%, $right: 0);
        @include size(40px);
        margin: 0;
        padding: 0;
        background-color: $color-main;
        border-color: $color-main;
        border-radius: $border-radius--round;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }
        
        svg {
            @include size(20px);
            fill: $color-white;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color : $color-bg--transparent;
            border-color: $color-main;

            svg {
                fill: $color-main;
            }
        }
    }
}


// //======================================================================================================
// // Search Page
// //======================================================================================================
.filter {
    .search-container {
        .searchInput:focus {
            border-color: $search-input-border-color--active !important;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }

}


// 960
@media screen and (max-width: $medium) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

}


// 640
@media screen and (max-width: $small) {

    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        @include font-size(40);
    }

}
