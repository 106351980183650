.header {
    position: relative;
    width: 100%;
    z-index: 5;
    height: 140px;

    &__container {
        background-color: $color-dark;
    }

    &__content {
        position: relative;
        height: 140px;
        @include flex($direction:column ,$alignItems: flex-end, $justifyContent: flex-end);
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include absolute($left:0);
    @include flex($alignItems: center, $justifyContent: center);
    @include size(230px, 140px);
    background-color: $color-white;
    z-index: 2;
    transition: none;

    &__title {
        margin: 0;
        font-size: 0;
        line-height: 0;
    }

    svg {
        @include size(170px, 93px);
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    @include flex($alignItems: center, $justifyContent:space-between);
    column-gap: 10px;
    @include size(auto,60px);
    border-bottom: 1px solid $color-white;
    @include absolute($top:0);
    padding-left: 30px;

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        margin: 0;
        padding: 0;
        background-color: $color-bg--transparent;
        font-size: $font-size--text-small;
        line-height: toRem(20);
        text-transform: uppercase;
        border: 0;
        cursor: pointer;
        color: $color-white;
        font-weight: $font-weight;
        column-gap: 5px;
        text-decoration: underline;
        text-decoration-color: $color-bg--transparent;
        transition: {
            property: text-decoration-color, border-color, background-color, color;
            duration: $duration;
            timing-function: $timing;
        }

        &--fb, &--insta, &--accessibility, &--search {
            @include size(35px);
            border: $border-width $border-style $color-white;
            border-radius: $border-radius--round;
            
            &:hover, &:focus-within {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }

        &--mobile {
            display: none;
        }

        &--push, &--nl {
            margin-right: 45px;

            &:hover, &:focus {
                text-decoration-color: $color-white;
                color: $color-white;
                background-color: $color-bg--transparent;
                border-color: $color-bg--transparent;
    
                svg {
                    fill: $color-white;
                }
            }
        }

        svg {
            @include size(20px);
            fill: $color-white;
            transition: fill $duration $timing;
        }

        &--search {
            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    
    }
}

.home {
    .header {
        position: absolute;

        &__container {
            background-color: $color-bg--transparent;
            transition: $duration;

            &.js-open-submenu {
                background-color: $color-dark;
            }
        }
    }

    .tools {
        filter: $shadow--filter;
    }

    .nav-main {
        .menu {
            &__link {
                filter: $shadow--filter;
            }
        }
    }
}

//======================================================================================================
// Header fixed
//======================================================================================================
@keyframes headerFixed {
    from {
        top: -200px;
    }
    to {
        top: 0;
    }
}

@keyframes headerFixedAdmin {
    from {
        top: -200px;
    }
    to {
        top: 32px;
    }
}

.header--fixed {
    .header {
        &__container {
            position: fixed;
            height: 80px;
            width: 100%;
            background-color: $color-dark;
            animation: 1s headerFixed;
            top: 0;
        }

        &__content {
            height: 80px;
        }
    }

    .identity {
        @include size(185px, 80px);

        svg {
            @include size(100px, 54px);
        }
    }

    .tools {
        transform: translateY(-100%);
        z-index: 2;
        box-shadow: none;

        &__item {
            &--search {
                position: fixed;
                bottom: -60px;
                z-index: 2;
                right: 0;
                background-color: $color-second;
                @include size(40px);
                border-radius: $border-radius--round;
                transition: background-color $duration ease-in-out;

                svg {
                    fill: $color-dark;
                }

                span {
                    display: none;
                }

                &:hover, &:focus {
                    background-color: $color-light;

                    svg {
                        fill:$color-dark;
                    }
                }
            }
        }
    }

    .nav-main {
        .menu {
            padding-right: 100px;

            &__link {
                filter: none;
            }
        }
    }
}

.admin-bar {
    .header--fixed {
        .header {
            &__container {
                top: 32px;
                animation: 1s headerFixedAdmin;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        height: 110px;
    
        &__content {
            height: 110px;
        }
    }

    .identity {
        @include size(180px,110px);

        svg {
            @include size(135px,auto);
        }
    }

    .tools {
        @include size(auto,55px);
        // column-gap: 10px;
        padding-left: 0;
    }

    .header--fixed {
        .identity {
            width: 145px;
        }

        .nav-main {
            .menu {
                column-gap: 40px;
                padding-right: 60px;
                
                &__link {
                    height: 80px;
                    padding-top: 0;

                    &:after {
                        bottom: 25px;
                    }
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        &__content {
            flex-direction: row;
            align-items: center;
            column-gap: 8px;
        }
    }

    .tools {
        position: relative;
        border-bottom: none;
        @include size(fit-content);
        column-gap: 8px;

        &__item {

            &--nl {
                border-radius: $border-radius--round;
                @include size(35px);
                border: 1px solid $color-white;
                margin-right: 0;
                
                &:hover, &:focus-within {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: $color-dark;
                    }
                }
            }

            &--push {
                font-size: toRem(12);
                margin-right: 15px;
            }

            span {
                display: none;
            }

        }
    }
}


// 640
// @media screen and (max-width: $small) {

// 800
@media screen and (max-width: 800px) {
    .header {
        &:after {
            content: '';
            position: fixed;
            bottom: 0;
            @include size(calc(var(--vw, 1vw) * 100),95px);
            background-color: $color-dark;
        }
    }

    .identity {
        left: 0;
        right: 0;
        margin: auto;
    }

    .tools {
        position: fixed;
        left: calc((100% - (90% - $gl-gutter-xs))/2);
        bottom: 47px;
        top: auto;
        z-index: 5;
        box-shadow: none;

        &__item {
            &--desktop {
                display: none;
            }

            &--mobile {
                display: flex;
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                height: 35px;
                background-color: $color-main;
                z-index: 5;
                width: 100vw;

                &:hover, &:focus {
                    background-color: $color-main;
                }
            }
        }
    }
}
