.post_navigation {
    background-color: $color-main;

    &__wrapper {
        @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
        gap: 30px;
        padding-top: 32px;
        padding-bottom: 32px;
    }

    .return,
    .prev,
    .next {
        a {
            position: relative;
            @include flex($alignItems: center);
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-white;
            text-decoration: underline;
            text-decoration-color: $color-bg--transparent;
            transition: all $duration $timing;
        }
    }

    .return {
        a {
            svg {
                @include size(26px);
                pointer-events: all;
                cursor: pointer;
                fill: $color-white;
                transition: all $duration $timing;
            }

            &:hover, &:focus {
                color: $color-white;
                text-decoration-color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    .prev, .next {
        // A changer en fonction de la taille du bouton "précédent"
        min-width: 110px;
    }

    .prev {
        .navigation_link {
            @include flex($justifyContent: flex-start);
        }

        a {
            &:before {
                content: "";
                display: inline-block;
                @include size(26px);
                background-color: $color-white;
                mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                mask-repeat: no-repeat;
                transition: background-color $duration $timing;
                margin-right: 5px;
            }
        }
    }

    .next {
        .navigation_link {
            @include flex($justifyContent: flex-end);
        }

        a {
            &:after {
                content: "";
                display: inline-block;
                @include size(26px);
                background-color: $color-white;
                mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                mask-repeat: no-repeat;
                transition: background-color $duration $timing;
                margin-left: 5px;
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link { 
                a {
                    color: $color-white;
                    text-decoration-color: $color-white;

                    &:before, &:after {
                        background-color: $color-white;
                    }
                }
            }
        }
    }
}

// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}


//======================================================================================================
// Responsive 
//======================================================================================================

/// Si les navigation link ont un style de bouton ///
// // 960
// @media screen and (max-width: $medium) {

//     .post_navigation {

//         &--container {
//             gap: 20px 0;
//         }

//         .prev, .next {
//             // A changer en fonction de la taille du bouton "suivant"
//             min-width: 135px;

//             // si besoin
//             .navigation_link a {
//                 padding: 15px 20px;
//             }
//         }
//     }
// }
/// END - Si les navigation link ont un style de bouton ///

// 640
@media screen and (max-width: $small) {

    .post_navigation {

        .prev, .next {
            min-width: auto;
        }

        .return {
            @include flex($justifyContent: center);
            order: 1;
            flex: 1 1 100%;
        }

    }
}
